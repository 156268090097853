.login-page {
    min-height: 100vh;
    display: flex;
}
.login-page .left {
    padding: 40px;
    width: 500px;
}

.login-page .left .box-content{
    padding: 30px;
}

.login-page .left .image {
    margin-bottom: 200px;
}

.login-page .left .sign-text {
    margin-bottom: 50px;
}

.login-page .left .sign-text .main{
    font-weight: bold;
    font-size: 1.1rem;
}

.login-page button{
    margin-top: 30px;
}

.login-page .right {
    background: url("../../assets/images/bg-auth-1-2.png");
    background-size: cover;
    width: calc(100% - 500px);
}

.login-page .right .blur {
    width: 40%;
    height: 50%;
    /* background-color: black; */
    backdrop-filter: blur(45px);
}

@media screen and (max-height: 800px){
    .login-page .left .image {
        margin-bottom: 60px;
    }

    .login-page .left .sign-text {
        margin-bottom: 10px;
    }
}
@media screen and (max-height: 600px){
    .login-page .left .image {
        margin-bottom: 20px;
    }
}



@media screen and (max-width: 1024px){
    .right {
        display: none;
    }

    .login-page .left {
       width: 100%;
       margin: 0 auto;
    }

    .login-page .left .image {
        text-align: center;
    }
}
    